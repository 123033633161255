<template>
    <div class="main">
        <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        title="提现" />

        <div class="withdrawAccount">
            <div class="tip">提现账号</div>
            <div class="accountAndEdit" @click="bindAccount()">
                <div class="account">{{ withdrawAccount }}</div>
                <div class="edit"></div>
            </div>
        </div>

        <div class="needDiamondTip">要提现的钻石</div>

        <div class="diamondArea">
            <div class="inputArea">
                <input pattern="\d*" v-model="amount" :placeholder="maxDiamond()">
            </div>
        </div>

        <div class="result">{{ formatResult() }}</div>

        <div class="taxTip">每笔提现，平台将代收7%的个人所得税</div>

        <div v-if="amount >= 10000 && amount <= account.avail_amount" class="commit" @click="commit()">确认提现</div>
        <div v-else class="commit1">确认提现</div>

        <van-popup v-model="safeCheckDialogVisiable">
            <div class="selfCheckDialog">
                <div class="title">安全验证</div>
                <div class="mobile">{{ mobile }}</div>
                <div class="smsArea">
                    <input class="inputArea" v-model="code">
                    <div class="getCode" @click="sendSimCode()">{{ getCodeTip() }}</div>
                </div>
                <div class="btns">
                    <div class="cancelBtn" @click="safeCheckDialogVisiable = false">取消</div>
                    <div class="confirmBtn" @click="confirmCommit()">确认</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { doRequest } from '@/utils/request'
import { Toast } from 'vant'

export default {
    data() {
        return {
            amount: '',
            account : {},
            withdrawAccount: '',

            safeCheckDialogVisiable: false,
            mobile: '',
            code: '',
            countDown: 0,
            timer: null,
        }
    },
    methods: {
        bindAccount() {
            this.$router.push({
                path: '/withdraw/bindAccount',
                query: this.$route.query,
            })
        },
        formatResult() {
            if (this.amount <= 0) {
                return ''
            }
            if (this.amount > this.account.avail_amount) {
                return '最多可提现' + this.account.avail_amount + '钻石'
            }
            if (this.amount < 10000) {
                return '最少提现10000钻石'
            }
            let total = parseInt(this.amount * 100 / this.account.withdraw_radio, 0)
            let fee = parseInt(total * 7 / 100, 0)
            return '共计' + parseFloat(total / 100.0).toFixed(2) +
            '元，其中代收个人所得税' + parseFloat(fee / 100.0).toFixed(2) +
            '元，到账' + parseFloat((total - fee) / 100.0).toFixed(2) + '元'
        },
        maxDiamond() {
            return '最多可提现' + this.account.avail_amount + '钻石'
        },
        onClickLeft() {
            this.$router.go(-1)
        },
        getCodeTip() {
            if (this.countDown == 0) {
                return '获取验证码'
            } else {
                return this.countDown + ''
            }
        },
        sendSimCode() {
            if (this.countDown > 0) {
                this.safeCheckDialogVisiable = true
                return
            }
            doRequest('/sms/sendCode', {
                code_id: 2,
            }, this.$route.query).then(res => {
                this.mobile = res.mobile
                this.safeCheckDialogVisiable = true
                this.countDown = 59
                this.timer = setInterval(() => {
                    if (this.countDown == 0) {
                        clearInterval(this.timer)
                        return
                    }
                    this.countDown --
                }, 1000)
            })
        },
        commit() {
            if (this.withdrawAccount.length == 0) {
                Toast.fail('请先绑定提现账号')
                return
            }
            if (this.amount <= 0) {
                Toast.fail('请输入有效的钻石数')
                return
            }
            if (this.amount > this.account.avail_amount) {
                Toast.fail(this.maxDiamond())
                return
            }

            this.sendSimCode()
        },
        confirmCommit() {
            doRequest('/withdraw/withdraw', {
                currency: parseInt(this.$route.query.currency, 0),
                amount: parseInt(this.amount, 0),
                code: this.code,
            }, this.$route.query).then(() => {
                Toast.success('已成功申请提现')
                this.safeCheckDialogVisiable = false
                this.initData()
            })
        },
        initData() {
            doRequest('/account/detail', {
                currency: parseInt(this.$route.query.currency, 0),
            }, this.$route.query).then(res => {
                this.account = res.detail
            })

            doRequest('/withdraw/getAccount', {}, this.$route.query).then(res => {
                this.withdrawAccount = res.account
            })
        },
    },
    mounted () {
        this.initData()
    },
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 375px;
}

.withdrawAccount {
    height: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 22px;
    margin-top: 15px;
    margin-right: 12px;
}

.withdrawAccount .tip {
    /* width: 57px; */
    height: 14px;
    line-height: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.withdrawAccount .accountAndEdit {
    width: 260px;
    height: 11px;
    display: flex;
    justify-content: flex-end;
}

.withdrawAccount .accountAndEdit .account {
    width: 260px;
    height: 11px;
    text-align: right;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 11px;
}

.withdrawAccount .accountAndEdit .edit {
    width: 7px;
    height: 11px;
    margin-left: 6px;
    background: url('../../assets/edit.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.needDiamondTip {
    /* width: 85px; */
    height: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    margin-left: 22px;
    margin-top: 39px;
}

.diamondArea {
    display: flex;
    align-items: center;
    width: 351px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin-top: 11px;
    margin-left: 12px;
}

.diamondArea .inputArea {
    height: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-left: 10px;
}

.result {
    height: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    margin-left: 22px;
    margin-top: 15px;
    margin-right: 12px;
}

.taxTip {
    width: 100%;
    text-align: center;
    height: 11px;
    font-size: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 11px;
    margin-top: 42px;
}

.commit {
    width: 273px;
    height: 55px;
    background: linear-gradient(-90deg, #F53760 0%, #FB6F8D 100%);
    box-shadow: 0px 5px 8px 0px #FFEFCD;
    border-radius: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    margin-top: 70px;
    margin-left: 52px;
}

.commit1 {
    width: 273px;
    height: 55px;
    background: linear-gradient(-90deg, #CCCCCC 0%, #CCCCCC 100%);
    box-shadow: 0px 5px 8px 0px #CCCCCC;
    border-radius: 28px;

    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

    margin-top: 70px;
    margin-left: 52px;
}

.selfCheckDialog {
    width: 318px;
    height: 260px;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.selfCheckDialog .title {
    height: 20px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3B3B38;
    line-height: 20px;
    margin-left: 24px;
    margin-top: 20px;
}

.selfCheckDialog .mobile {
    height: 13px;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 13px;
    margin-left: 24px;
    margin-top: 19px;
}

.selfCheckDialog .smsArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 270px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin-top: 25px;
    margin-left: 24px;
}

.selfCheckDialog .smsArea .inputArea {
    height: 30px;
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin-left: 20px;
}

.selfCheckDialog .smsArea .getCode {
    width: 100px;
    height: 15px;
    text-align: center;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 15px;
    border-left: 1px solid #999999;
}

.selfCheckDialog .btns {
    height: 45px;
    width: 269px;
    display: flex;
    justify-content: space-between;
    margin-top: 37px;
    margin-left: 26px;
}

.selfCheckDialog .btns .cancelBtn {
    width: 122px;
    height: 45px;
    border: 1px solid #F53760;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #F53760;
}

.selfCheckDialog .btns .confirmBtn {
    width: 122px;
    height: 45px;
    background: linear-gradient(-90deg, #F53760 0%, #FB6F8D 100%);
    border-radius: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

input {
    width: 150px;
    outline:none;
    background:transparent;
    border:none;
    outline:medium;
}
</style>